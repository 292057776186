<template>
  <PSelect 
    :disabled="disabled"
    :items="trucktypes" 
    v-model="stdTruckType" 
    :label="$tk('PTrucktype.StandardTruckType')" 
    itemValue="id" 
    itemText="name" 
  />
</template>

<script>
  import api from "../api"
  import { find } from "lodash"
  
  export default {
    name: "PTruckType",

    props: {
      value: {
        type: String,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        trucktypes: [],
        stdTruckType: this.value
      }
    },

    watch: {
      stdTruckType(value) {
        this.$emit('input', value)
      }
    },

    async created () {
      this.trucktypes = await api.trucktypesGet();

      if (this.value === null || this.value === undefined || this.value === "") { // No selected value is provided, use default from trucktypes
        try {
          this.stdTruckType = find(this.trucktypes, (t) => { return t.isStandard }).id
        } catch {
          this.stdTruckType = null
        }
      }
    },
  }
</script>