<template>
  <PSelect 
    :label="$tk('PCountrySelect.Country')" 
    :items="countries" 
    :disabled="disabled"
    itemText="text" 
    v-model="selectedCountry"  
    itemValue="value" 
  />
</template>

<script>
  import api from "../api"
  import { filter, map, first } from "lodash"

  export default {
    name: "CountrySelect",
    props: {
      value: {
        type: String
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        selectedCountry: "",
        countries: [],
      }
    },
    watch: {
      selectedCountry (value) {
        this.$emit('input', value) 
      }
    },
    async created () {
      let allCountries = await api.countriesGet()
      let filteredCountries = filter(allCountries, element => {
        return element.priority < 3
      })
      this.countries = map(filteredCountries, element => {
        return {
          value: element.id,
          text: element.name
        }
      })
      this.selectedCountry = first(this.countries).value
    }
  }
</script>
