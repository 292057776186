<template>
  <div class="flex flex-wrap w-full overflow-y-auto">
    <div class="md:w-1/3">

      <PCard class="bg-white">

        <PCardTitle>
          <span v-html="$tk('Company.CompanyLogo')"></span>
        </PCardTitle>

        <PCardContent>
          
          <img class="max-w-full mx-auto" v-if="customer.image" :src="`${assetsBaseUrl}${customer.image}`" />
          
          <div class="mt-4 flex justify-between">
            
            <PButton 
              @click="onDelete" 
              color="danger" 
              :disabled="!customer.image"              
            >
              <span v-html="$tk('Common.Actions.Delete')"></span>
            </PButton>

            <PButton 
              @click="logoDialogVisible = true"
              v-html="customer.image 
                ? $tk('Common.Actions.Change') 
                : $tk('Common.Actions.Select')
            "></PButton>

          </div>

        </PCardContent>

      </PCard>

    </div>

    <div class="w-full md:w-2/3 md:pl-4 mt-4 md:mt-0">
      
      <PCard class="bg-white">
        
        <PCardTitle> 
          <span v-html="$tk('Company.CompanyInformation')"></span>
        </PCardTitle>

        <PCardContent>
          
          <PInput 
            :disabled="saving"
            :label="$tk('Company.InvoiceReference')" 
            v-model="customer.invoiceRef" 
          />

          <PInput 
            class="mt-4" 
            :disabled="saving"
            :label="$tk('Company.ContactEmail')" 
            v-model="customer.contactEmail" 
          />

          <PInput 
            class="mt-4" 
            :disabled="saving"
            :label="$tk('Company.Phone')" 
            v-model="customer.contactPhone" 
          />

          <PInput 
            class="mt-4" 
            :disabled="saving"
            :label="$tk('Company.EmailInvoice')" 
            v-model="customer.emailInvoice" 
          />

          <PInput 
            class="mt-4" 
            :disabled="saving"
            :label="$tk('Company.EmailPurchase')" 
            v-model="customer.emailPurchase" 
          />

          <PInput 
            class="mt-4" 
            :disabled="saving"
            :label="$tk('Company.EmailOrderConfirmed')" 
            v-model="customer.emailOrderConfirmed" 
          />

        </PCardContent>

        <PCardActions>

          <PButton 
            :disabled="saving" 
            @click="saveEmails()"
            v-html="submitText"
          ></PButton>

        </PCardActions>

      </PCard>

    </div>

    <LogoDialog 
      v-if="logoDialogVisible" 
      @close="onCloseLogoDialog"
    ></LogoDialog>

  </div>
</template>

<script>

import LogoDialog from "./dialogs/LogoDialog.vue"

import api from "../api"
import { mapGetters } from "vuex"

export default {
    
    name: "Company",
    
    components: {
      LogoDialog
    },
    
    data () {
      return {
        site: location.hostname.split(".")[0].toUpperCase(),
        
        timestamp: Date.now(),
        saving: false,

        logoDialogVisible: false
      }
    },

    computed: {
      
      ...mapGetters(["customer", "customerHasLogo"]),
      
      assetsBaseUrl() {        
        return this.$appConfig.assetsBaseUrl
      },      
      
      submitText () {
        return this.saving 
        ? this.$tk("Common.Actions.Saving") 
        : this.$tk("Common.Actions.Save") 
      }

    },

    methods: {

      onCloseLogoDialog () {
        this.logoDialogVisible = false
      },

      async onDelete () {
        try {

          await api.customerLogoDelete(this.customer.id)
          this.$store.commit("setCustomerImage", "")

        } catch (e) {

          this.$notifyError(
            this.$tk("Company.CouldNotDeleteLogo", true),
            this.$formatApiError(e)
          )

        }
      },

      async saveEmails() {
        try {
          
          this.saving = true
          
          await api.customerPost(
            this.customer.id, 
            this.customer.name, 
            this.customer.contactEmail, 
            this.customer.contactPhone, 
            this.customer.invoiceRef, 
            this.customer.emailInvoice,
            this.customer.emailPurchase, 
            this.customer.emailOrderConfirmed
          )

          this.$notifySuccess(this.$tk("Company.CompanyInfoSaved", true))

        } catch (e) {

          this.$notifyError(
            this.$tk("Company.ErrorSavingCompanyInfo", true), 
            this.$formatApiError(e)
          )

        } finally {

          this.saving = false

        }
      }
    }
  }
</script>